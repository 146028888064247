// src/styles/tokens.ts
const tokens = {
    colors: {
      background: "#0D0D0D",
      textPrimary: "#FFFFFF",
      textHighlight: "#08EC83",
      textSecondary: "#C0C0C0",
      buttonBackground: "#1E1E1E",
      buttonText: "#FFFFFF",
      inputBackground: "#2D2D2D90",
      inputText: "#FFFFFF",
      cardBackground: "#171717",
      gradientStart: "#1C1C1C",
      gradientEnd: "#0D0D0D",
      highlight: "#FF00F7",
      footerGradientStart: "#0D0D0D",
      footerGradientCenter: "#ba38b6",
      footerGradientEnd: "#0D0D0D",
    },
    fonts: {
      primary: "'Arial', sans-serif",
      secondary: "'Helvetica', sans-serif",
    },
    sizes: {
      headerHeight: "80px",
      fontSizeTitle: "3.5rem",
      fontSizeSubtitle: "1.25rem",
      fontSizeNav: "1rem",
      fontSizeButton: "1rem",
      fontSizeInput: "1rem",
      borderRadius: "40px",
      footerHeight: "150px",
    }
  };
  
  export default tokens;
  