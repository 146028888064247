// src/components/Footer.tsx
import React from 'react';
import { Content, Divider, FooterWrapper, Gradient } from './styles';

const Footer: React.FC = () => {
    return (
        <FooterWrapper>
            <Divider />
            <Content>
                    O trabalho duro vence o talento.<br />Seja um especialista!
                </Content>
            <Gradient />
        </FooterWrapper>
    );
};

export default Footer;
