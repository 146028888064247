import React from 'react';

import { Container } from './styles';

const Button: React.FC<propsCard> = ({ type, text, className }) => {
    return (
        <Container className={className}>
            {text}
        </Container>
    );
};

export default Button;
