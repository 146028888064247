import React, { useState } from 'react';

import { Container, Content, Description, Divider, Header, Icon, Image, ImageContent, Label, Title } from './styles';

import imgReactNative from '../../assets/img-react-native.png'
import imgFirebase from '../../assets/img-firebase.png'
import imgDeploy from '../../assets/img-deploy.png'
import imgCiCd from '../../assets/img-ci-cd.png'
import imgChevronDown from '../../assets/img-chevron-down.png'
import imgChevronUp from '../../assets/img-chevron-up.png'

const Accordion: React.FC = () => {
    const [items, setItems] = useState([
        {
            title: 'React Native',
            icon: imgReactNative,
            isActive: false,
            description: 'Vamos juntos criar um app trazendo os conceitos mais modernos atualmente do mercado',
            data: []
        },
        {
            title: 'Firebase',
            icon: imgFirebase,
            isActive: false,
            description: 'Muito além do Authentication ou Firestore, o Firebase possui recursos muitos poderosos que possibilita trackear o usuário em seu aplicativo, debugar e até mesmo distribuir releases em integração conítinua. Vamos aprender mais sobre:',
            data: [
                {
                    label: 'Remote Config'
                },
                {
                    label: 'Firebase Distribution'
                },
                {
                    label: 'Analytics'
                },
                {
                    label: 'Analytics 360'
                },
                {
                    label: 'Crashlytics'
                },
                {
                    label: 'Events/Key Events'
                },
                {
                    label: 'E muito mais sobre Firebase.'
                },
            ]
        },
        {
            title: 'Deploy',
            icon: imgDeploy,
            isActive: false,
            data: [
                {
                    label: 'Criação de contas Google Play Console e Apple Developer Program'
                },
                {
                    label: 'Certificações'
                },
                {
                    label: 'Segurança em aplicações e ambientes'
                },
            ]
        },
        {
            title: 'CI/CD',
            icon: imgCiCd,
            isActive: false,
            description: 'Entender sobre esteira CI/CD é um dos diferenciais de um desenvolvedor React Native hoje em dia. Então vamos abordar sobre',
            data: [
                {
                    label: 'Esteira CI/CD'
                },
                {
                    label: 'Fluxos de trabalho, testes, regressivos e ambientes'
                },
                {
                    label: 'Pipelines'
                },
                {
                    label: 'Yalm e Fastlane'
                }
            ]
        },
    ])

    const onCLickHeader = (indexPress: number) => {
        setItems(items.map((item, index) => {
            return indexPress === index ? { ...item, isActive: !item.isActive } : { ...item, isActive: false }
        }))
    }

    return (
        <Container>
            {items.map((item, index) => (
                <Content>
                    <Header onClick={() => onCLickHeader(index)}>
                        <ImageContent>
                            <Icon src={item.icon} />
                        </ImageContent>
                        <Title>{item.title}</Title>
                        <Image src={item.isActive ? imgChevronUp : imgChevronDown} />
                    </Header>
                    {item.isActive && (
                        <>
                            <Description>{item.description}</Description>
                            <Divider />
                            {item.data.map((value) => (
                                <Label>{value.label}</Label>
                            ))}
                        </>
                    )}
                </Content>
            ))}
        </Container>
    );
};

export default Accordion;
