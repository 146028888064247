
import styled from 'styled-components';
import tokens from '../../styles/tokens';

export const Container = styled.div`
    width: 80%;
    max-width: 550px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #111111;
    margin-bottom: 60px;
`;

export const Content = styled.div`
  background-color: ${tokens.colors.inputBackground};
  width: 90%;
  max-width: 540px;
  margin-top: 20px;
  border-radius: 20px;
  padding: 20px;
  border-style: solid;
  border-width: 0.5px;
  border-color:  ${tokens.colors.textSecondary};
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

export const Image = styled.img`
  width: 20px;
  height: 20px;
`

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  filter: brightness(0);
`

export const ImageContent = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${tokens.colors.textSecondary};
`

export const Title = styled.h1`
  width: 100%;
  font-size: 20px;
  margin-left: 14px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
`

export const Description = styled.p`
  width: 100%;
  font-size: 14px;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #a6a6a6;
`

export const Label = styled.li`
  width: 90%;
  margin-top: 8px;
  font-size: 14px;
  margin-left: 20px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: ${tokens.colors.textSecondary};
`