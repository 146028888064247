
import styled from 'styled-components';

export const Container = styled.div<{color: string}>`
    width: 100%;
    max-width: 300px;
    height: 100px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    z-index: 2;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 20px;
    border-radius: 80px;
    border-width: 4px;
    border-top-style: solid;
    border-left-style: solid;
    border-right-style: solid;
    border-color: ${({ color }) => color};

`;

export const Content = styled.div`
    width: 100%;
    max-width: 300px;
    height: 100px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    z-index: 2;
    color: #FFFFFF;
    border-radius: 80px;
    opacity: 0.5;
    position: absolute;
    background: rgba(255, 255, 255, 0.2); /* Fundo semitransparente */
    backdrop-filter: blur(10px); /* Desfoque */
    -webkit-backdrop-filter: blur(10px); /* Desfoque para navegadores que usam o prefixo -webkit- */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Borda semitransparente */
    padding: 20px; /* Espaçamento interno */
    color: #fff; /* Cor do texto */
    z-index: -1;
    
`;

export const Circle = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ color }) => color};
`

export const Image = styled.img`
    width: 30px;
    height: 30px;
`