import React from 'react';
import { Circle, Container, Content, Image } from './styles';

import imgReactNative from '../../assets/img-react-native.png'
import imgFirebase from '../../assets/img-firebase.png'
import imgDeploy from '../../assets/img-deploy.png'
import imgCiCd from '../../assets/img-ci-cd.png'

const Card: React.FC<propsCard> = ({ type, text }) => {
    const getColors = (): string => {
        if (type === 'react-native') {
            return '#456ABC'
        }

        if (type === 'firebase') {
            return '#e38737'
        }

        if (type === 'cicd') {
            return '#ac30d1'
        }

        return '#6da815'
    }

    const getIcon = () => {
        if (type === 'react-native') {
            return imgReactNative;
        }

        if (type === 'firebase') {
            return imgFirebase
        }

        if (type === 'cicd') {
            return imgCiCd
        }

        return imgDeploy
    }
    return (
        <Container className='card' color={getColors()}>
            {text}
            <Circle color={getColors()}>
                <Image src={getIcon()} />
            </Circle>
            <Content className='card' />
        </Container>
    );
};

export default Card;
