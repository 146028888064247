
import styled from 'styled-components';
import tokens from '../../styles/tokens';

export const FooterWrapper = styled.footer`
  /* background: linear-gradient(90deg, 
    ${tokens.colors.footerGradientStart} 20%, 
    ${tokens.colors.footerGradientCenter} 50%, 
    ${tokens.colors.footerGradientEnd} 80%); */
  text-align: center;
  color: ${tokens.colors.textSecondary};
  font-size: ${tokens.sizes.fontSizeNav};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  background-color: #000000;
`;

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, 
    ${tokens.colors.footerGradientStart} 20%, 
    #FFFFFF80 50%, 
    ${tokens.colors.footerGradientEnd} 80%);
  top: 0px;
  z-index: 3;
`;

export const Content = styled.div`
/* width: 100%; */
height: ${tokens.sizes.footerHeight};
display: flex;
align-items: center;
justify-content: center;
align-self: center;
z-index: 2;
`;

export const Gradient = styled.div`
width: 50%;
height: ${tokens.sizes.footerHeight};
display: flex;
padding: 20px;
position: absolute;
align-items: center;
justify-content: center;
background:
    linear-gradient(to bottom, rgba(255, 0, 247, 0), #000000 100%, #000000 100%, rgba(0, 5, 2,1) 100%, rgba(255, 0, 247, 0)) bottom,
    linear-gradient(to right, rgba(255, 0, 247, 0), #000000 0%, #a00791 50%, #000000 100%, rgba(255, 0, 247, 0)) right;
background-size: 100% 100%, 100% 100%, 100% 100%;
background-repeat: no-repeat;
z-index: 1;
`;