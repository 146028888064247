import React, { useEffect, useState } from 'react';
import GlobalStyles from './styles/GlobalStyles';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import styled from 'styled-components';
import Card from './components/Card';
import Button from './components/Button';
import Accordion from './components/Accordion';

export const Back = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 100px 20px;
  background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5) 10%, rgba(0, 0, 0, 0)) top,
    linear-gradient(to bottom, rgba(255, 0, 247, 0), rgba(0, 0, 0, 1) 100%, rgba(255, 0, 247, 0.8) 60%, rgba(0, 5, 2, 1) 100%,  rgba(0, 0, 0, 1)) bottom,
    linear-gradient(to right, rgba(255, 0, 247, 0), hsla(301.7391304347826, 77.52808988764046%, 17.45098039215686%, 0.5) 10%, rgba(255, 0, 247, 0.8) 50%, rgb(3, 0, 2) 90%,  rgba(0, 0, 0, 1)) right,
    linear-gradient(to left, rgba(255, 0, 247, 0), hsla(301.7391304347826, 77.52808988764046%, 17.45098039215686%, 0.5) 20%, rgba(0, 0, 0, 1) 0%, rgb(0, 0, 0) 100%, rgba(0, 0, 0, 1)) left;
background-size: 100% 100%, 100% 100%, 100% 100%;
background-repeat: no-repeat;
  filter: blur(3.5px);
`;

const ContentCards = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 100px;
    gap: 40px;
`

const App: React.FC = () => {
    const [showButton, setShowButton] = useState(false);
  
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
  
      if (scrollTop > 100 && scrollTop + windowHeight < documentHeight - 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    return (
      <div className="wrapper">
        <GlobalStyles />
        <MainContent />
        <ContentCards>
          <Card type="react-native" text="React Native" />
          <Card type="firebase" text="Firebase" />
          <Card type="deploy" text="Deploy" />
          <Card type="cicd" text="CI/CD" />
        </ContentCards>

        <Accordion />
        <Footer />
        <Button 
          text="Entrar na lista" 
          type='firebase' 
          className={`fixed-button ${showButton ? 'visible' : 'hidden'}`} 
        />
        <Back />
      </div>
    );
  };
  
  export default App;



