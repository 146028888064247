import styled, { keyframes } from 'styled-components';

const skeletonLoading = keyframes`
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: calc(200px + 100%) 0;
    }
`;

export const Container = styled.div`
    width: 80%;
    padding: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    color: #FFFFFF;
    border-radius: 80px;
    background-color: #08EC83;
    position: fixed;
    bottom: 20px;
    display: none;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    animation: ${skeletonLoading} 1.5s infinite;
    background-image: linear-gradient(90deg, #08EC83 25%, #69f9b6 50%, #08EC83 75%);
`;