import React from 'react';
import { Button, ContentInput, Highlight, Input, InputWrapper, MainWrapper, Subtitle, Title, Text } from './styles';
import tokens from '../../styles/tokens';

const MainContent: React.FC = () => {
    return (
        <MainWrapper>
            <Title>
                <Highlight color={tokens.colors.textHighlight}>React Native</Highlight>,<br/>
                a fórmula avançada!
            </Title>
            <Subtitle>
                Aprenda as <Highlight color="#FFFFFF">principais e mais usadas ferramentas</Highlight> por grandes empresas.
            </Subtitle>
            <InputWrapper>
                <ContentInput>
                    <Input type="email" placeholder="Seu melhor email" />
                    <Button>Cadastrar</Button>
                </ContentInput>
            </InputWrapper>

            <Text>Se torne um <Highlight color={tokens.colors.textHighlight}>especialista React native</Highlight>. Confira os módulos:</Text>
        </MainWrapper>
    );
};

export default MainContent;
