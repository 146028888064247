import styled from 'styled-components';
import tokens from '../../styles/tokens';

export const MainWrapper = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px 20px;
`;

export const Highlight = styled.span`
  color: ${({color}) => color};
  font-family: ${tokens.fonts.secondary};
  font-weight: bold;
  margin: 20px 0;
`;

export const Title = styled.h1`
  font-size: ${tokens.sizes.fontSizeTitle};
  color: ${tokens.colors.textPrimary};
  font-family: ${tokens.fonts.secondary};
  margin: 20px 0;
`;

export const Subtitle = styled.p`
  font-size: ${tokens.sizes.fontSizeSubtitle};
  color: ${tokens.colors.textPrimary};
  margin: 20px 0;
  max-width: 600px;
`;

export const InputWrapper = styled.div`
  display: flex;
  margin: 20px 0;
  width: 100%;
  max-width: 500px;
`;

export const ContentInput = styled.div`
  border: none;
  border-radius: 40px;
  background-color: ${tokens.colors.inputBackground};
  color: ${tokens.colors.inputText};
  border: 1px solid #5b5656;
  flex: 1;
  display: flex;
  align-items: center;
  font-size: ${tokens.sizes.fontSizeInput};
`;

export const Input = styled.input`
  width: 80%;
  padding: 15px;
  border: none;
  background-color: transparent;
  color: ${tokens.colors.inputText};
  font-size: ${tokens.sizes.fontSizeInput};
  outline: none;
`;

export const Button = styled.button`
  padding: 15px 20px;
  margin-right: 2px;
  margin-bottom: 2px;
  border: none;
  border-radius: ${tokens.sizes.borderRadius};
  background-color: #08EC83;
  color: ${tokens.colors.buttonText};
  font-weight: bold;
  cursor: pointer;
`;

export const Text = styled.a`
  font-size: 26px;
  color: ${tokens.colors.textPrimary};
  font-family: ${tokens.fonts.secondary};
  margin-top: 20px;
`;

