// src/styles/GlobalStyles.ts
import { createGlobalStyle } from 'styled-components';
import tokens from './tokens';

const GlobalStyles = createGlobalStyle`
  html body {
    margin: 0;
    font-family: ${tokens.fonts.primary};
    background-color: ${tokens.colors.background};
    color: ${tokens.colors.textPrimary};
    max-width: 100%;
    overflow-x: hidden;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    /* filter: blur(3.5px); */
  }
  .container {
    position: absolute;
    display: flex;
    height: 100vh;
    filter: blur(3.5px);
    background: linear-gradient(180deg, ${tokens.colors.gradientStart} 0%, ${tokens.colors.gradientEnd} 100%);
  }
  .cards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 100px;
  }

  .fixed-button.hidden {
  opacity: 0;
  pointer-events: none;
}

.fixed-button.visible {
  display: block;
  opacity: 1;
  max-width: 300px;
}

/* Para telas menores */
@media (max-width: 600px) {
  .cards {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
}
`;

export default GlobalStyles;
